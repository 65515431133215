<template>
  <v-footer padless height="160px" color="transparent">
    <v-container>
      <v-row align="center" justify="center">
        <v-col class="text-center align-center">
          <v-logo :simple="true" width="100px"></v-logo>
          <span class="text-caption">&copy; Copyright 2025 Hounddog. All Rights Reserved. </span>
        </v-col>
      </v-row>
    </v-container>
    <v-speed-dial
      v-model="helpButtons"
      fixed
      bottom
      right
      :direction="direction"
      :open-on-hover="hover"
      :transition="transition"
    >
      <template v-slot:activator>
        <v-btn
          v-model="helpButtons"
          color="secondary"
          dark
          fab
        >
          <v-icon v-if="helpButtons">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-help
          </v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        small
        color="green"
        @click="processData(true)" v-if="user.super == 1"
      >
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
        small
        color="indigo"
        @click="showHelp"
      >
        <v-icon>mdi-lifebuoy</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
        small
        color="red"
      >
        <v-icon>mdi-comment-question</v-icon>
      </v-btn>
    </v-speed-dial>
  </v-footer>
</template>

<script>
  export default {
    name: 'Footer.vue',
    data(){
      return {
        helpButtons: false,
        direction: 'top',
        hover: false,
        transition: 'scale-transition',
      }
    },
    computed:{
      user(){
        return this.$store.getters['users/user'];
      },
    },
    methods:{
      async processData(force = false){
        if(this.user.super == 1){
          await this.$store.dispatch("users/all", { force });
        }
        await this.$store.dispatch("integrations/get");
        await this.$store.dispatch("sites/all", { force });
        await this.$store.dispatch("scans/all", { force });
        await this.$store.dispatch("userMeta/get");
        await this.$store.dispatch("messages/all", { force });
        await this.$store.dispatch("transactions/credits");
        await this.$store.dispatch("posts/all", { force });
        await this.$store.dispatch("postsCategories/all", { force });
        if(force){
          document.location.reload();
        }
      },
      showHelp(){
        this.$store.dispatch('system/setHelp', false);
        this.$root.hideHelp = false;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .v-footer{
    .logo-wrapper{
      .logo{
        padding: 0;
      }
    }
  }
</style>