<template>
  <v-form
    ref="form"
    v-model="form.valid"
    lazy-validation>
    <v-card elevation="0" outlined>
      <v-card-title>Update Password</v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col>
            <v-text-field
              type="password"
              v-model="form.fields.password"
              :rules="[$utilities.rules.required, $utilities.rules.min]"
              label="Password"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-text-field
              type="password"
              v-model="form.fields.confirm"
              :rules="[$utilities.rules.required, $utilities.rules.min, rules.mustMatch]"
              label="Confirm"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-row>
          <v-col class="text-center">
            <v-btn dark color="primary" @click="save">
              Save
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>  
  </v-form>
</template>

<script>
export default {
  name: 'UserPassword.vue',
  data() {
    return {
      rules: {
        mustMatch: v => this.form.fields.password === v || 'Passwords must match',
      },
      form:{
        valid: false,
        fields: {
          password: '',
          confirm: '',
        },
      },
    }
  },
  methods:{
    save(){
      let self = this;
      if(this.$refs.form.validate()){
        this.$store.dispatch("users/passwordUpdate", this.form.fields)
          .then(() => {
            setTimeout(() => {
              self.form.fields.password = '';
              self.form.fields.confirm = '';
            },1000)
          });
      }
    },
  }
}
</script>

<style lang="scss">

</style>