<template>
  <div class="headline-container">
    <transition name="fade-slide-up">
      <span v-if="sentences[currentIndex]" :key="currentIndex" class="headline-item">
        <span v-if="showIcons" class="headline-icon">
          <v-icon>{{ sentences[currentIndex].icon }}</v-icon>
        </span>
        {{ sentences[currentIndex].content }}
      </span>
    </transition>
  </div>
</template>

<script>
export default {
  name: "HeadlineCarousel",
  props: {
    sentences: {
      type: Array,
      required: true,
    },
    interval: {
      type: Number,
      default: 5000,
    },
    showIcons: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentIndex: 0,
    };
  },
  mounted() {
    this.startCycle();
  },
  beforeDestroy() {
    this.stopCycle();
  },
  methods: {
    startCycle() {
      this.timer = setInterval(() => {
        this.nextSentence();
      }, this.interval);
    },
    stopCycle() {
      clearInterval(this.timer);
    },
    nextSentence() {
      this.currentIndex =
        (this.currentIndex + 1) % this.sentences.length;
    },
  },
};
</script>

<style lang="scss">

.headline-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  .headline-item {
    .headline-icon{
      display: block;
      margin-bottom: 10px;
      i{
        color: #fff;
        font-size: 60px;
      }
    }
    font:{
      size: 60px;
      family: "Manrope";
      weight: 500;
    }
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.9);
    line-height: 1;
    color: white;
    text-align: center;
    margin: 40px;
    position: absolute;
    word-wrap: break-word;
  }
}

.fade-slide-up-enter-active,
.fade-slide-up-leave-active {
  transition: opacity 0.6s ease, transform 0.6s ease;
}
.fade-slide-up-enter {
  opacity: 0;
  transform: translateY(20px);
}
.fade-slide-up-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}
</style>