import Vue            from 'vue'
import apiService     from '@/services/api.js';
import i18n           from '@/lang';

const model = '/audit-questions';
const endpoints = {
  all:              model+'/all',
  allWithAnswers:   model+'/all-with-answers',
  get:              model+'/get',
  insert:           model+'/insert',
  update:           model+'/update',
  delete:           model+'/delete',
  toggle:           model+'/toggle',
}
const mutation_calls = {
  all:              'SET_ALL',
  allWithAnswers:   'SET_ALL_WITH_ANSWERS',
  get:              'SET_GET',
  insert:           'SET_INSERT',
  update:           'SET_UPDATE',
  delete:           'SET_DELETE',
  toggle:           'SET_TOGGLE',
}

const initialState = () => ({
  all: null,
  allWithAnswers: null,
  get: null,
});

const state = initialState();

const getters = {
  all: state => state.all,
  allWithAnswers: state => state.allWithAnswers,
  get: state => state.get,
}

const actions = {
  all(context) {
    return new Promise((resolve, reject) => {
      if(context.rootGetters['auditQuestions/all'] != null){
        return;
      }
      let user = context.rootGetters['users/user'];
      apiService.post(endpoints.all,{
        'user_id': user.id
      })
      .then(response => {
        let payload = response.data;
        context.commit(mutation_calls.all, payload);
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("questions.all.status.error"), { root: true })
        reject(error);
      });
    });
  },
  allWithAnswers(context, fields) {
    return new Promise((resolve, reject) => {
      if(context.rootGetters['auditQuestions/allWithAnswers'] != null){
        return;
      }
      let user = context.rootGetters['users/user'];
      apiService.post(endpoints.allWithAnswers,{
        'user_id': user.id,
        'site_id': fields.site_id
      })
      .then(response => {
        let payload = response.data;
        context.commit(mutation_calls.allWithAnswers, payload);
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("questions.all.status.error"), { root: true })
        reject(error);
      });
    });
  },
  async get(context, fields) {
    return new Promise((resolve, reject) => {
      let user = context.rootGetters['users/user'];
      if(fields.id == 0){
        return resolve();
      }
      let apiParams = { 
        'id': fields.id,
        'user_id': user.id
      };
      apiService.post(endpoints.get, apiParams)
      .then(response => {
        let payload = response.data;
        context.commit(mutation_calls.get, payload);
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("questions.all.status.error"), { root: true })
        reject(error);
      });
    });
  },  
  insert(context, fields) {
    return new Promise((resolve, reject) => {
      let user = context.rootGetters['users/user'];
      apiService.post(endpoints.insert, {
        'question': fields.question,
        'user_id': user.id,
        'sort': context.rootGetters['auditQuestions/all'].length+1
      })
      .then(response => {
        let payload = response.data[0];
        context.commit(mutation_calls.insert, payload);
        context.dispatch('system/setMessage', i18n.t("questions.insert.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("questions.insert.status.error"), { root: true })
        reject(error);
      });
    });
  },
  update(context, fields) {
    return new Promise((resolve, reject) => {
      let user = context.rootGetters['users/user'];
      apiService.post(endpoints.update, {
        'question': fields.question,
        'active': fields.active,
        'sort': fields.sort,
        'id': fields.id,
        'user_id': user.id
      })
      .then(response => {
        let payload = response;
        context.commit(mutation_calls.update, fields);
        context.dispatch('system/setMessage', i18n.t("questions.update.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("questions.update.status.error"), { root: true })
        reject(error);
      });
    });
  },
  delete(context, fields) {
    return new Promise((resolve, reject) => {
      let user = context.rootGetters['users/user'];
      apiService.post(endpoints.delete, { 
        'id': fields.id,
        'user_id': user.id
      })
      .then(response => {
        let payload = response;
        context.commit(mutation_calls.delete, fields.id);
        context.dispatch('system/setMessage', i18n.t("questions.delete.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("questions.delete.status.error"), { root: true })
        reject(error);
      });
    });
  },
  toggle(context, fields) {
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.toggle, {
        'id': fields.id,
      })
      .then(response => {
        let payload = response.data[0];
        let active = payload.active;
        context.commit(mutation_calls.toggle, {
          id: fields.id,
          active: active
        });
        if(active == 1){
          context.dispatch('system/setMessage', i18n.t("questions.toggle.status.activated"), { root: true })
        }else{
          context.dispatch('system/setMessage', i18n.t("questions.toggle.status.deactivated"), { root: true })
        }
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("questions.toggle.status.error"), { root: true })
        reject(error);
      });
    });
  },
}

const mutations = {
  SET_ALL(state, payload) {
    state.all = payload.sort((t1,t2) => t1.sort > t2.sort ? 1 : -1);
  },
  SET_ALL_WITH_ANSWERS(state, payload) {
    state.allWithAnswers = payload.sort((t1,t2) => t1.sort > t2.sort ? 1 : -1);
  },
  SET_GET(state, payload) {
    state.get = payload;
  },
  SET_INSERT(state, payload) {
    state.all.push(payload);
  },
  SET_UPDATE(state, payload) {
    const index = state.all.findIndex(question => question.id === payload.id);
    if (index !== -1) {
      Vue.set(state.all, index, payload);
    }
    state.all = state.all.sort((t1,t2) => t1.sort > t2.sort ? 1 : -1);
  },
  SET_DELETE(state, payload) {
    const index = state.all.findIndex(question => question.id === payload);
    state.all.splice(index, 1);
  },
  SET_TOGGLE(state, payload) {
    state.all = state.all.map(question => {
      if (question.id === payload.id) {
        question.active = payload.active;
      }
      return question;
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}