import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import store from "@/stores";

const routes = [
  {
    path: "/",
    component: () => import("@/themes/Application.vue"),
    children: [
      {
        name: "Dashboard",
        path: "/",
        component: () => import("./views/auth/Home.vue"),
        meta: {
          requiresAuth: true,
          requiresSuper: false,
          title: "Dashboard",
        },
      },
      {
        name: "Scans",
        path: "/scans",
        component: () => import("./views/auth/Scans.vue"),
        meta: {
          requiresAuth: true,
          requiresSuper: false,
          title: "Scans",
        },
      },
      {
        name: "Sites",
        path: "/sites",
        component: () => import("./views/auth/Sites.vue"),
        meta: {
          requiresAuth: true,
          requiresSuper: false,
          title: "Sites",
        },
      },
      {
        name: "Site",
        path: "/site/:id",
        component: () => import("./views/auth/Site.vue"),
        meta: {
          requiresAuth: true,
          requiresSuper: false,
          title: "Site",
        },
      },
      {
        name: "Account",
        path: "/account",
        component: () => import("./views/auth/Account.vue"),
        meta: {
          requiresAuth: true,
          requiresSuper: false,
          title: "Your Account",
        },
      },
      {
        name: "Integrations",
        path: "/integrations",
        component: () => import("./views/auth/Integrations.vue"),
        meta: {
          requiresAuth: true,
          requiresSuper: false,
          title: "Integrations",
        },
      },
      {
        name: "Reports Settings",
        path: "/reports-settings",
        component: () => import("./views/auth/Reports.vue"),
        meta: {
          requiresAuth: true,
          requiresSuper: false,
          title: "Reports Settings",
        },
      },
      {
        name: "Billing",
        path: "/billing",
        component: () => import("./views/auth/Billing.vue"),
        meta: {
          requiresAuth: true,
          requiresSuper: false,
          title: "Billing",
        },
      },
      {
        name: "Payment Success",
        path: "/payment/success/:token",
        component: () => import("./views/stripe/Success.vue"),
        meta: {
          requiresAuth: true,
          requiresSuper: false,
          title: "Payment Success",
        },
      },
      {
        name: "Payment Cancelled",
        path: "/payment/cancel/:token",
        component: () => import("./views/stripe/Cancel.vue"),
        meta: {
          requiresAuth: true,
          requiresSuper: false,
          title: "Payment Cancelled",
        },
      },
      {
        name: "Users",
        path: "/users",
        component: () => import("./views/auth/Users.vue"),
        meta: {
          requiresAuth: true,
          requiresSuper: true,
          title: "Users",
        },
      },
      {
        name: "System Messages",
        path: "/messages",
        component: () => import("./views/auth/Messages.vue"),
        meta: {
          requiresAuth: true,
          requiresSuper: true,
          title: "System Messages",
        },
      },
      {
        name: "Spelling",
        path: "/spelling",
        component: () => import("./views/auth/Spelling.vue"),
        meta: {
          requiresAuth: true,
          title: "Spelling",
        },
      },
      {
        name: "Tests",
        path: "/tests",
        component: () => import("./views/auth/Tests.vue"),
        meta: {
          requiresAuth: true,
          title: "Tests",
        },
      },
      {
        name: "Resources",
        path: "/resources",
        component: () => import("./views/auth/Resources.vue"),
        meta: {
          requiresAuth: true,
          title: "Resources",
        },
      },
      {
        name: "KnowledgeBase",
        path: "/knowledge-base",
        component: () => import("./views/auth/Posts/Index.vue"),
        meta: {
          requiresAuth: true,
          title: "Knowledge Base",
        },
      },
      {
        name: "KnowledgeBaseCategory",
        path: "/knowledge-base/category/:id",
        component: () => import("./views/auth/Posts/Category.vue"),
        meta: {
          requiresAuth: true,
          title: "Knowledge Base Category",
        },
      },
      {
        name: "KnowledgeBasePost",
        path: "/knowledge-base/post/:id",
        component: () => import("./views/auth/Posts/Post.vue"),
        meta: {
          requiresAuth: true,
          title: "Knowledge Base",
        },
      },
      {
        name: "Email Templates",
        path: "/email-templates",
        component: () => import("./views/auth/Emails.vue"),
        meta: {
          requiresAuth: true,
          title: "Email Templates",
        },
      },
      {
        name: "Audit Questions",
        path: "/audit-questions",
        component: () => import("./views/auth/AuditQuestions.vue"),
        meta: {
          requiresAuth: true,
          title: "Audit Questions",
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/themes/PublicTwoColumn.vue"),
    children: [
      {
        name: "Login",
        path: "/login",
        component: () => import("./views/Login.vue"),
        meta: {
          requiresAuth: false,
        },
      },
      {
        name: "Register",
        path: "/register",
        component: () => import("./views/Register.vue"),
        meta: {
          requiresAuth: false,
        },
      },
      {
        name: "Logout",
        path: "/logout",
        component: () => import("./views/auth/Logout.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "Password",
        path: "/password/:token",
        component: () => import("./views/Password.vue"),
        meta: {
          requiresAuth: false,
        },
      },
      {
        name: "Callback",
        path: "/callback",
        component: () => import("./views/Callback.vue"),
        meta: {
          requiresAuth: false,
        },
      },
    ]
  },
  {
    path: "/",
    component: () => import("@/themes/Public.vue"),
    children: [
      {
        name: "404",
        path: "*",
        component: () => import("./views/404.vue"),
        meta: {
          error: true,
          requiresAuth: false,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  routes,
});

router.beforeEach((to, from, next) => {
  // REDIRECT AWAY FROM THERE PAGES IF LOGGED IN
  if (["/login", "/password"].includes(to.path) && store.state.users.auth) {
    router.replace("/");
  }
  // *****************

  // REDIRECT IF PAGE BEING ACCESSED REQUIRES AUTH AND NO AUTH PRESENT
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.state.users.auth) {
      next(); // PRIVATE
    } else {
      router.replace("/login"); // REDIRECT TO LOGIN
    }
  } else {
    next(); // PUBLIC
  }

  if (to.matched.some((record) => record.meta.requiresSuper)) {
    if (store.state.users.user.super == 1) {
      next(); // PRIVATE
    } else {
      router.replace("404"); // 404
    }
  } else {
    next(); // PUBLIC
  }

  // REDIRECT IF 404 PAGE
  if (to.matched.some((record) => record.meta.error)) {
    if (store.state.users.auth) {
      setTimeout(() => {
        router.replace("/");
      }, 1000);
    } else {
      setTimeout(() => {
        router.replace("/login");
      }, 1000);
    }
  }
});

export default router;
