import Vue from 'vue';

import FieldsetBasic                from './FieldsetBasic.vue';
import Fieldset                     from './Fieldset.vue';
import HeadlineCarousel             from './HeadlineCarousel.vue';
import Help                         from './Help.vue';
import Sidebar                      from './Sidebar.vue';
import Zen                          from './Zen.vue';

Vue.component('v-fieldset-basic',   FieldsetBasic);
Vue.component('v-fieldset',         Fieldset);
Vue.component('v-headline-carousel', HeadlineCarousel);
Vue.component('v-help',             Help);
Vue.component('v-sidebar',          Sidebar);
Vue.component('v-zen',              Zen);
