<template>
  <div class="header">
    <v-modal-support v-model="dialogSupport"></v-modal-support>
    
    <v-app-bar color="transparent" light height="100px" elevation="0" v-if="user">
      <v-toolbar-title>
        <a href="/" plain text inline>
          <v-logo :show-png="true" width="110px"></v-logo>
        </a>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-tabs height="100px" background-color="transparent" color="secondary">
          <v-tab v-for="(item, index) in this.authItems.filter(obj => obj.active === true)" :id="`auth-tab-`+item.id" :key="index" :to="item.url">
            {{ item.title }}           
          </v-tab>
        </v-tabs>
      </v-toolbar-items>

      <v-spacer></v-spacer>
      <v-menu
        v-model="notifications"
        :close-on-content-click="false"
        :nudge-width="600"
        :nudge-bottom="58"
        offset-x
        v-if="messages && messages.length > 0"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon plain v-bind="attrs" v-on="on" class="mr-5">
            <v-icon color="primary">mdi-bell</v-icon>
          </v-btn>
        </template>

        <v-card tile>
          <v-list color="white" light>
            <v-subheader class="secondary--text">System Messages</v-subheader>
            <v-divider></v-divider>
            <v-list-item v-for="(item, index) in messages" :key="index">
              <a :href="item.link" v-if="item.link">
                {{item.content}}
              </a>
              <span v-else>
                {{item.content}}
              </span>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            plain
            v-bind="attrs"
            v-on="on"
            class="mr-5"
            elevation="0"
          >
          <v-avatar color="white" size="40">
            <v-icon color="primary">mdi-cog</v-icon>
          </v-avatar>
          </v-btn>
        </template>
        <v-list width="250px" light color="white">
          <v-subheader class="secondary--text">Settings</v-subheader>
          <v-divider></v-divider>
          <v-list-item v-for="(item, index) in this.settingsMenus.filter(obj => obj.active === true)" :key="index" :to="item.url">
            <v-list-item-icon>
              <v-icon color="primary">{{item.icon}}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{item.title}}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            class="mr-5"
            elevation="0"
          >
          <v-avatar color="primary" size="40">
            <v-gravatar :email="user.email" />
          </v-avatar>
          </v-btn>
        </template>
        <v-list width="250px" light color="white">
          <v-subheader class="secondary--text">Account</v-subheader>
          <v-divider></v-divider>
          <v-list-item class="pl-3 pr-3" v-if="!this.user.super">
            <div class="d-flex justify-space-between align-center" style="width: 100%;">
              <v-chip small>{{getRemainingCredits()}}</v-chip>
              <v-btn to="/billing" x-small color="primary" light>Buy More</v-btn>
            </div>
          </v-list-item>
          <v-list-item v-for="(item, index) in this.adminItems" :key="index" :to="item.url">
            <v-list-item-icon>
              <v-icon color="primary">{{item.icon}}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{item.title}}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      notifications: false,
      dialogSupport: false,
      adminDropdownItems:[
        {
          id: 'account',
          icon: 'mdi-account-box',
          title: 'Account', 
          url: '/account'
        },
        {
          id: 'support',
          icon: 'mdi-help-circle',
          title: 'Support', 
          url: '#support',
        },
        {
          id: 'logout',
          icon: 'mdi-logout',
          title: 'Logout', 
          url: '/logout'
        },
      ],
      superDropdownItems:[
        {
          id: 'users',
          icon: 'mdi-account-multiple',
          title: 'Users', 
          url: '/users'
        },
        {
          id: 'messages',
          icon: 'mdi-message-bulleted',
          title: 'Messages', 
          url: '/messages'
        },
        {
          id: 'emails',
          icon: 'mdi-email',
          title: 'Email Templates', 
          url: '/email-templates',
          active: true,
        },
      ],
      settingsMenus:[
        {
          id: 'settings',
          title: 'Settings',
          url: '#',
        },
        {
          id: 'audit',
          icon: 'mdi-folder-question',
          title: 'Audit Questions', 
          url: '/audit-questions',
          active: true,
        },
        {
          id: 'integrations',
          icon: 'mdi-link',
          title: 'Integrations', 
          url: '/integrations',
          active: true,
        },
        {
          id: 'reports',
          icon: 'mdi-file-chart',
          title: 'Report Settings', 
          url: '/reports-settings',
          active: true,
        },
        {
          id: 'billing',
          icon: 'mdi-wallet',
          title: 'Billing', 
          url: '/billing',
          active: true,
        },
      ],
      authItems:[
        {
          id: 'dashboard',
          title: 'Dashboard', 
          url: '/',
          active: true,
          
        },
        {
          id: 'sites',
          title: 'Sites', 
          url: '/sites',
          active: true,
          super: false, 
        },
        {
          id: 'scans',
          title: 'Scans', 
          url: '/scans',
          active: true,
        },
        {
          id: 'knowledge-base',
          title: 'Knowledge Base', 
          url: '/knowledge-base',
          active: true,
        },
        {
          id: 'tests',
          title: 'Tests', 
          url: '/tests',
          active: false,
        },
      ]
    }
  },
  async created(){
    if(this.user){
      await this.processData();
      await this.$store.dispatch("system/getHelp");
    }
  },
  computed:{
    user(){
      return this.$store.getters['users/user'];
    },
    credits(){
      return this.$store.getters['transactions/credits'];
    },
    messages(){
      return this.$store.getters['messages/all'];
    },
    adminItems(){
      if(this.user.super == 1){
        return this.superDropdownItems.concat(this.adminDropdownItems);
      }else if(this.user.super != 1){
        return this.adminDropdownItems;
      }else{
        return [];
      }
    }
  },
  methods:{
    async processData(force = false){
      if(this.user.super == 1){
        await this.$store.dispatch("users/all", { force });
      }
      await this.$store.dispatch("integrations/get");
      await this.$store.dispatch("sites/all", { force });
      await this.$store.dispatch("scans/all", { force });
      await this.$store.dispatch("userMeta/get");
      await this.$store.dispatch("messages/all", { force });
      await this.$store.dispatch("transactions/credits");
      await this.$store.dispatch("categories/all", { force });
      await this.$store.dispatch("auditQuestions/all", { force });
      
      if(force){
        document.location.reload();
      }
    },
    getRemainingCredits(){
      if(this.credits){
        if(this.user.super == 1){
          return 'Unlimited';
        }else{
          return 'Credits: ' + this.credits.remaining_credits
        }
      }else{
        return 0;
      }
    }
  },
  watch: {
    '$route'(to) {
      if(to.hash == '#support'){
        this.dialogSupport = true;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .header{
    @media print {  
      display: none;
    }
    background-color: rgba(255,255,255,0.7);
    box-shadow: 0 4px 8px -1px rgba(0,0,0,0.1)!important;
    position: relative;
    z-index: 1;
  }
  .v-app-bar{
    max-width: 1200px;
    margin: 0 auto;
  }
  .v-list{
    padding: 0px;
    .v-list-item:not(:last-child){
      border-bottom: 1px solid rgba(#ccc,0.4);
    }
  }
  table{
    tr{
      td{
        font-size: 12px !important;
      }
    }
  }
</style>
