import Vue from "vue";

import Debug from "./Debug.vue";
import Details from "./Details.vue";
import Reorder from "./Reorder.vue";
import Delete from "./Delete.vue";
import DeleteAll from "./DeleteAll.vue";
import Email from "./Email.vue";
import Attachment from "./Attachment.vue";
import Scan from "./Scan.vue";
import AttachmentRename from "./Rename.vue";

Vue.component("v-modal-scan-debug", Debug);
Vue.component("v-modal-scan-details", Details);
Vue.component("v-modal-scan-reorder", Reorder);
Vue.component("v-modal-scan-delete", Delete);
Vue.component("v-modal-scan-delete-all", DeleteAll);
Vue.component("v-modal-scan-email", Email);
Vue.component("v-modal-scan-attachment", Attachment);
Vue.component("v-modal-scan", Scan);
Vue.component("v-modal-scan-attachment-rename", AttachmentRename);
