<template>
  <v-dialog v-model="value" width="60%" persistent>
    <v-card>
      <v-card-title>
        <span class="text-h5">Delete Scan?</span>
        <v-btn small dark fab absolute right text @click="updateValue(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="text-center py-5">
        <b>Are you sure you want to do this?</b>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="py-5">
        <v-spacer></v-spacer>
        <v-btn dark color="primary" @click="save">Yes</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ScanDelete.vue',
  data() {
    return {
    }
  },
  emits: ['update:modelValue'],
  props:{
    value: {
      type: Boolean,
      default: false,
    },
    id:{
      type: Number
    },
  },
  methods:{
    updateValue (value) {
      this.$emit('input', value);
    },
    async save(){
      this.$store.dispatch("scans/delete", {
        id: this.id
      });
      this.updateValue(false);
    }
  }
}
</script>

<style>

</style>