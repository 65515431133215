<template>
  <v-layout align-center>
    <v-btn text plain to="/knowledge-base">Knowledge Base</v-btn>

    <template v-if="category_id && !post">
      <span class="mx-2">/</span>
      <span class="secondary--text v-btn v-size--default">{{category_name}}</span>
    </template>

    <template v-if="category_id && post">
      <span class="mx-2">/</span>
      <v-btn text plain :to="`/knowledge-base/category/${category_id}`">{{category_name}}</v-btn>
    </template>

    <template v-if="post">
      <span class="mx-2">/</span>
      <span class="secondary--text v-btn v-size--default">{{post.title}}</span>
    </template>

  </v-layout>
</template>

<script>
export default {
  name: 'PostsBreadcrumbs',
  data() {
    return {

    };
  },
  methods: {

  },
  props: {
    category_id: {
      type: [Number, String]
    },
    category_name: {
      type: [String]
    },
    post: {
      type: [Object]
    }
  }
};
</script>

<style scoped>

</style>

