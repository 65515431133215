import Vue from "vue";

import Forgot from "./Forgot.vue";
import Insert from "./Insert.vue";
import Update from "./Update.vue";
import Delete from "./Delete.vue";
import ResendInvite from "./ResendInvite.vue";
import Gift from "./Gift.vue";

Vue.component("v-modal-users-forgot", Forgot);
Vue.component("v-modal-users-insert", Insert);
Vue.component("v-modal-users-update", Update);
Vue.component("v-modal-users-delete", Delete);
Vue.component("v-modal-users-resend-invite", ResendInvite);
Vue.component("v-modal-users-gift", Gift);
