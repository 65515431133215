import Vue from "vue";

import CompanyLogo                  from './CompanyLogo.vue';
import Logo                         from './Logo.vue';
import PageTitle                    from './PageTitle.vue';
import PageSubtitle                 from './PageSubTitle.vue';

Vue.component('v-company-logo',     CompanyLogo);
Vue.component('v-logo',             Logo);
Vue.component('v-page-title',       PageTitle);
Vue.component('v-page-subtitle',    PageSubtitle);
