<template>
  <div>
    <v-modal-audit-answers-insert v-model="dialogInsert" :siteId="id" :questionId="questionId" :question="question" :answer="answer"></v-modal-audit-answers-insert>

    <v-card class="rounded-0" elevation="0" v-if="id">
      <v-card-text class="pa-0">
        <v-container fluid class="pa-0">
          <v-row class="pa-0">
            <v-col>
              <v-loader v-if="!questions"></v-loader>
              <v-expansion-panels v-else>
                <v-expansion-panel v-for="question in questions" :key="question.id">
                  <v-expansion-panel-header ripple color="#efefef" class="pa-2">
                    <v-layout align-center>
                      <v-icon class="mr-2" color="red" v-if="question.answer === null">mdi-help-circle-outline</v-icon>
                      <v-icon class="mr-2" color="success" v-else>mdi-check-circle-outline</v-icon>
                      <b>{{ question.question }}</b>
                    </v-layout>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="position: relative; overflow: hidden;">
                    <v-container class="pa-5">
                      <v-row>
                        <v-col align="center" v-if="question.answer == null">
                          <v-btn color="secondary" dark @click="insertDialog(question)">Answer this question!</v-btn>
                        </v-col>
                        <v-col style="position: relative;" v-else>
                          {{question.answer}}
                          <div style="position: absolute; top: 10px; right: 0px;">
                            <v-btn
                              absolute
                              top
                              right
                              fab
                              dark
                              plain
                              x-small
                              color="grey"
                              @click="insertDialog(question)"
                            >
                              <v-icon small>mdi-pencil</v-icon>
                            </v-btn>
                          </div>                            
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
          </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>

</template>

<script>
export default {
  name: 'SiteAudit.vue',
  data() {
    return {
      actionButtons: false,
      selectedObject: 0,
      siteId: 0,
      questionId: 0,
      question: '',
      answer: '',
      dialogInsert: false,
      items:[],
    }
  },
  async created(){
    this.all();
  },
  computed:{
    id(){
      return this.$route.params.id;
    },
    user(){
      return this.$store.getters['users/user'];
    },
    questions(){
      return this.$store.getters['auditQuestions/allWithAnswers'];
    },
  },
  methods:{
    async all(){
      let self = this;
      await this.$store.dispatch("auditQuestions/allWithAnswers", {'site_id': this.id})
        .then((data) => {
          self.items = data;
        });
    },
    insertDialog(item){
      this.questionId = item.question_id;
      this.question = item.question;
      this.answer = item.answer;
      this.dialogInsert = true;
    },
  },
  watch:{
    id(){
      this.all();
    }
  }
}
</script>

<style>
.v-expansion-panel-content__wrap{
  padding: 0px !important;
}
</style>