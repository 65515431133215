<template>
  <v-dialog v-model="value" max-width="900px" width="80%" persistent>
    <v-form ref="form" v-model="form.valid">
      <v-card>
        <v-card-title>
          <span class="text-h5">New Post</span>
          <v-spacer></v-spacer>
          <v-btn small dark fab right text @click="updateValue(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="form.fields.title"
                  type="text"
                  outlined
                  required
                  placeholder="Title"
                  @keydown.enter.prevent
                  label="Title"
                  :rules="[$utilities.rules.required]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  label="Category"
                  v-model="form.fields.category_id"
                  :items="categories"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  required
                  placeholder="Category"
                  @keydown.enter.prevent
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <tiptap-vuetify min-height="300px" v-model="form.fields.message" :extensions="extensions"></tiptap-vuetify>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-5">
          <v-spacer></v-spacer>
          <v-btn dark color="primary" @click="save">Save</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
} from "tiptap-vuetify";

export default {
  name: 'PostInsert.vue',
  data() {
    return {
      defaultItem: {},
      form:{
        valid: false,
        fields:{
          title: '',
          message: '',
          category_id: 0,
          parent_id: 0,
        }
      },
      extensions: [
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak,
      ],

    }
  },
  async created(){
    this.defaultItem = this.form.fields;
  },
  emits: ['update:modelValue'],
  components: {
    TiptapVuetify,
  },
  props:{
    value:{
      type: Boolean,
      default: false
    },
  },
  computed:{
    categories(){
      return this.$store.getters['categories/all'];
    }
  },
  methods:{
    updateValue (value) {
      this.$emit('input', value);
    },
    async save(){
      if(this.$refs.form.validate()){
        await this.$store.dispatch("posts/insert", this.form.fields);
        this.updateValue(false);
      }else{
        this.systemMessage('Please validate the form!');
      }
    }
  }
}
</script>

<style>

</style>