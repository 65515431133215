<template>
  <v-sheet class="mx-auto" :class="{'mb-50': !alwaysOpen}" elevation="0" centered>
    <div class="filter-bar" :class="{'close': (!open && !alwaysOpen)}">
      <v-sheet class="filter-bar-inner mx-auto py-0" elevation="0" color="primary" dark centered>
        <slot></slot>
      </v-sheet>
    </div>
    <v-card max-width="1170px" class="mx-auto" elevation="0" color="transparent" v-if="!alwaysOpen">
      <v-btn @click="open = !open" elevation="0" small :outlined="!open" color="primary" dark absolute style="border-top: solid 0px #fff;border-top-left-radius: 0; border-top-right-radius: 0; right: 47%;" class="pl-5 pr-3 py-1">
        filter
        <v-icon>
          {{open ? 'mdi-chevron-up' : 'mdi-chevron-down'}}
        </v-icon>
      </v-btn>
    </v-card>
  </v-sheet>
</template>

<script>
  export default {
    name: 'PageSubtitle.vue',
    props:{
      title: {
        type: String,
        default: '',
      },
      alwaysOpen: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        open: false,
      }
    },
    async created(){

    },
  }
</script>

<style lang="scss" scoped>
  .mb-50{
    margin-bottom: 40px;
  }
  .filter-bar{
    position: relative;
    overflow: hidden;
    height: 72px;
    transition: height 0.3s ease-in-out;
    &.close{
      height: 0;
    }
    .filter-bar-inner{
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
</style>