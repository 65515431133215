<template>
  <v-dialog v-model="value" max-width="650px" persistent>
    <v-card>
      <v-card-title>
        <span class="text-h5">Delete Question?</span>
        <v-spacer></v-spacer>
        <v-btn small dark fab right text @click="updateValue(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="text-center py-5" v-if="this.id">
        <b>Are you sure you want to delete that question?</b>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="py-5">
        <v-spacer></v-spacer>
        <v-btn dark color="primary" @click="save">Yes</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AuditQuestionDelete.vue',
  data() {
    return {
    }
  },
  emits: ['update:modelValue'],
  props:{
    value:{
      type: Boolean,
      default: false
    },
    id:{
      type: [Number, String]
    },
  },
  methods:{
    updateValue (value) {
      this.$emit('input', value);
    },
    async save(){
      let self = this;
      await this.$store.dispatch("auditQuestions/delete", {
        id: this.id,
      })
      .then(() =>{
        self.updateValue(false);
      });
    }
  }
}
</script>

<style>

</style>