<template>
  <v-card elevation="0" outlined>
    <v-card-title>
      <v-layout>
        <v-flex>
          Account Info
        </v-flex>
        <v-flex class="text-right">
          <v-btn to="/billing" elevation="0" color="primary" small dark>Buy More</v-btn>
        </v-flex>
      </v-layout>
    </v-card-title>
    <v-card-text class="pa-0">
      <v-simple-table class="no-hover">
        <template v-slot:default>
          <tbody>
            <tr>
              <th width="40%">Total Sites</th>
              <td align="right">{{getTotalSites()}}</td>
            </tr>
            <tr>
              <th>Total Scans</th>
              <td align="right">{{getTotalScans()}}</td>
            </tr>
            <tr>
              <th>Account Expires</th>
              <td align="right">
                {{getExpiry()}}
              </td>
            </tr>
            <tr>
              <th>Credits Remaining</th>
              <td align="right">
                {{getRemainingCredits()}}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'AccountInfo.vue',
    data() {
      return {
      }
    },
    computed:{
      user(){
        return this.$store.getters['users/user'];
      },
      meta(){
        return this.$store.getters['userMeta/get'];
      },
      sites(){
        return this.$store.getters['sites/all'];
      },
      scans(){
        return this.$store.getters['scans/all'];
      },
      credits(){
        return this.$store.getters['transactions/credits'];
      },
    },
    methods:{
      getTotalScans(){
        if(this.scans){
          return this.scans.length;
        }else{
          return 'Loading...';
        }
      },
      getTotalSites(){
        if(this.sites){
          return this.sites.length;
        }else{
          return 'Loading...';
        }
      },
      getRemainingCredits(){
        if(this.credits){
          if(this.credits.user_frequency == '∞'){
            return 'Unlimited';
          }else{
            return (this.credits.remaining_credits) ? this.credits.remaining_credits : 0;
          }
        }else{
          return 'Loading...';
        }
      },
      getExpiry(){
        if(this.user){
          if(this.user.expires){
            return this.$date.fullDateTime(this.user.expires);
          }else{
            return "User doesn't expire";
          }
        }else{
          return 'Loading...';
        }
      }
    },
  }
</script>

<style>

</style>