<template>
  <v-card max-width="1200px" class="mx-auto my-5" elevation="0">
    <v-btn icon @click="close" absolute top right>
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-container class="pa-5">
      <v-row dense align="start">
        <v-col cols="auto" class="mr-5">
          <slot name="icon"></slot>
        </v-col>
        <v-col>
          <slot name="title"></slot>
          <slot name="content"></slot>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
  export default {
    name: 'Help.vue',
    data() {
      return {
      }
    },
    async created(){
      this.$root.hideHelp = await this.getHelp();
    },
    methods: {
      async getHelp(){
        let hide = await this.$store.dispatch('system/getHelp');
        if(hide == 'true'){
          return true;
        }else{
          return false;
        }
      },
      close() {
        this.$root.hideHelp = true;
        this.$store.dispatch('system/setHelp', true);
      }
    }
  }
</script>

<style>
  .slideUp{
    height: 0;
    transform: translateY(-100%);
    transition: transform 0.5s ease-in-out;
    opacity: 0;
  }
</style>