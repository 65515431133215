<template>
  <v-dialog v-model="value" max-width="900px" width="80%" persistent>
    <v-form ref="form" v-model="form.valid">
      <v-card>
        <v-card-title>
          <span class="text-h5">Update Category</span>
          <v-spacer></v-spacer>
          <v-btn small dark fab right text @click="updateValue(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="form.fields.name"
                  type="text"
                  outlined
                  required
                  placeholder="Name"
                  @keydown.enter.prevent
                  label="Name"
                  :rules="[$utilities.rules.required]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                Active: {{ form.fields.active }}
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-5">
          <v-spacer></v-spacer>
          <v-btn dark color="primary" @click="save">Save</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'CategoryUpdate.vue',
  data() {
    return {
      form:{
        valid: false,
        fields:{
          name: '',
          active: 1
        }
      },
    }
  },
  emits: ['update:modelValue'],
  props:{
    value:{
      type: Boolean,
      default: false
    },
    id:{
      type: [Number, String]
    },
  },
  computed:{
    categories(){
      return this.$store.getters['categories/all'];
    }
  },
  created(){
    this.get();
  },
  methods:{
    updateValue (value) {
      this.$emit('input', value);
    },
    async get(){
      this.category = await this.$store.dispatch('categories/get', {id: this.id});
      if(this.category){
        this.form.fields = {...this.category};
      }
    },
    async save(){
      if(this.$refs.form.validate()){
        await this.$store.dispatch("categories/update", {...this.form.fields, id: this.id});
        this.updateValue(false);
        Vue.set(this.$parent, 'category', this.form.fields);
      }else{
        this.systemMessage('Please validate the form!');
      }
    }
  },
  watch:{
    id(){
      this.get();
    }
  }
}
</script>

<style>

</style>