import Vue from 'vue';

import GTMetrix                     from './GTMetrix.vue';
import Google                       from './Google.vue';
import Norton                       from './Norton.vue';
import ManageWP                     from './ManageWP.vue';
import Lighthouse                   from './Lighthouse.vue';
import McAfee                       from './McAfee.vue';
import PageSpeed                    from './PageSpeed.vue';
import Sucuri                       from './Sucuri.vue';
import VirusTotal                   from './VirusTotal.vue';
import Wave                         from './Wave.vue';
import Wordfence                    from './Wordfence.vue';
import YSlow                        from './YSlow.vue';


Vue.component('v-icon-gtmetrix',         GTMetrix);
Vue.component('v-icon-google',           Google);
Vue.component('v-icon-norton',           Norton);
Vue.component('v-icon-managewp',         ManageWP);
Vue.component('v-icon-lighthouse',       Lighthouse);
Vue.component('v-icon-mcafee',           McAfee);
Vue.component('v-icon-pagespeed',        PageSpeed);
Vue.component('v-icon-sucuri',           Sucuri);
Vue.component('v-icon-virustotal',       VirusTotal);
Vue.component('v-icon-wave',             Wave);
Vue.component('v-icon-wordfence',        Wordfence);
Vue.component('v-icon-yslow',            YSlow);
