<template>
  <div v-if="logo" class="isp-logo">
    <img :src="logo" :style="{'max-width': maxWidth, 'max-height': maxHeight}">
  </div>
</template>

<script>
import aws from '@/assets/isps/aws.svg';
import bluehost from '@/assets/isps/bluehost.svg';
import heroku from '@/assets/isps/heroku.svg';
import hostgator from '@/assets/isps/hostgator.svg';
import pantheon from '@/assets/isps/pantheon.svg';
import siteground from '@/assets/isps/siteground.svg';
import wpengine from '@/assets/isps/wpengine.svg';

export default {
  name: 'vIspLogo',
  props: {
    name: {
      type: String,
      required: true,
    },
    maxWidth: {
      type: String,
      default: '200px',
    },
    maxHeight: {
      type: String,
      default: '200px',
    },
  },
  computed: {
    logo() {
      if (!this.name) {
        return null;
      }
      switch (this.name.toLowerCase().replace(/\s/g, '')) {
        case 'aws':
          return aws;
        case 'heroku':
          return heroku;
        case 'hostgator':
          return hostgator;
        case 'pantheon':
          return pantheon;
        case 'siteground':
          return siteground;
        case 'wpengine':
          return wpengine;
        case 'bluehost':
          return bluehost;
        default:
          return null;
      }
    },
  },
};
</script>

<style scoped>
.isp-logo {
  width: 200px;
  height: auto;
  margin: 20px auto;
}
</style>