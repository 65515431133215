import Vue from "vue";
import apiService from "@/services/api.js";
import i18n from "@/lang";

const model = "/scans";
const endpoints = {
  all: model + "/all",
  get: model + "/get",
  byClient: model + "/client",
  insert: model + "/insert",
  update: model + "/update",
  delete: model + "/delete",
  updateRecommendations: model + "/update/recommendations",
  updateAttachments: model + "/update/attachments",
  updateLabel: model + "/update/label",
  updateReport: model + "/update/report",
  sendReport: model + "/send-report",
  start: "/report/start",
  end: "/report/end",
};
const mutation_calls = {
  all: "SET_ALL",
  get: "SET_GET",
  byClient: "SET_BYCLIENT",
  insert: "SET_INSERT",
  update: "SET_UPDATE",
  updateAttachments: "SET_UPDATE_ATTACHMENTS",
  delete: "SET_DELETE",
  updateRecommendations: "SET_UPDATE_RECOMMENDATIONS",
  updateLabel: "SET_UPDATE_LABEL",
  updateReport: "SET_UPDATE_REPORT",
  sendReport: "SET_SEND_REPORT",
  start: "SET_SCAN_START",
  end: "SET_SCAN_END",
  reset:            'SET_RESET',
};

const initialState = () => ({
  all: null,
  get: null,
  client: null,
});

const state = initialState();

const getters = {
  all: (state) => state.all,
  get: (state) => state.get,
  client: (state) => state.client,
};

const actions = {
  async all(context) {
    return new Promise((resolve, reject) => {
      let user = context.rootGetters["users/user"];
      let apiParams = {
        user_id: user.id,
      };
      apiService
        .post(endpoints.all, apiParams)
        .then((response) => {
          let payload = response.data;
          context.commit(mutation_calls.all, payload);
          resolve(payload);
        })
        .catch((error) => {
          context.dispatch("system/setMessage", i18n.t("general.error"), {
            root: true,
          });
          reject(error);
        });
    });
  },
  async byClient(context, fields) {
    return new Promise((resolve, reject) => {
      let apiParams = {
        client_id: fields.client_id,
      };
      apiService
        .post(endpoints.byClient, apiParams)
        .then((response) => {
          let payload = response.data;
          context.commit(mutation_calls.byClient, payload);
          resolve(payload);
        })
        .catch((error) => {
          context.dispatch("system/setMessage", i18n.t("general.error"), {
            root: true,
          });
          reject(error);
        });
    });
  },
  get(context, fields) {
    return new Promise((resolve, reject) => {
      if (fields.id == 0) {
        return resolve();
      }
      let apiParams = {
        id: fields.id,
      };
      apiService
        .post(endpoints.get, apiParams)
        .then((response) => {
          context.commit(mutation_calls.get, response.data);
          resolve(response.data);
        })
        .catch((error) => {
          context.dispatch("system/setMessage", i18n.t("general.error"), {
            root: true,
          });
          reject(error);
        });
    });
  },
  async insert(context, fields) {
    return new Promise((resolve, reject) => {
      let user = context.rootGetters["users/user"];
      let apiParams = {
        report_theme: fields.report_theme,
        report_label: fields.report_label,
        recommendations: fields.recommendations,
        hide_logo: fields.hide_logo,
        client_id: fields.client_id,
        user_id: user.id,
        site_name: fields.site_name,
        new_site_url: fields.new_site_url,
        report_list: fields.scanList
      };
      apiService
        .post(endpoints.insert, apiParams)
        .then((response) => {
          let payload = response.data;
          context.commit(mutation_calls.insert, payload);
          resolve(payload);
        })
        .catch((error) => {
          context.dispatch("system/setMessage", i18n.t("general.error"), {
            root: true,
          });
          reject(error);
        });
    });
  },
  update(context, fields) {
    return new Promise((resolve, reject) => {
      apiService
        .post(endpoints.update, {
          name: fields.name,
          report_label: fields.report_label,
          report_theme: fields.report_theme,
          id: fields.id,
        })
        .then((response) => {
          let payload = response;
          context.commit(mutation_calls.update, fields);
          context.dispatch("system/setMessage", i18n.t("scans.update.status.success"), { root: true });
          resolve(payload);
        })
        .catch((error) => {
          context.dispatch("system/setMessage", i18n.t("general.error"), {
            root: true,
          });
          reject(error);
        });
    });
  },
  updateAttachments(context, fields) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();

      const itemsToUpload = fields.files.filter((i) => i.uploaded === true);
      const itemsFromURL = fields.files.filter((i) => i.uploaded === false);

      formData.append("id", fields.id);
      formData.append("pdf", JSON.stringify(itemsFromURL));

      itemsToUpload.forEach((upload, index) => {
        formData.append(`attachments[${index}].id`, `${fields.id}-${upload.label}`);
        formData.append(`attachments[${index}].file`, upload.file);
      });

      apiService
        .post(endpoints.updateAttachments, formData)
        .then((response) => {
          let payload = response.data;
          context.commit(mutation_calls.updateAttachments, payload);
          context.dispatch("system/setMessage", i18n.t("scans.update.status.success"), { root: true });
          resolve(payload);
        })
        .catch((error) => {
          context.dispatch("system/setMessage", i18n.t("general.error"), {
            root: true,
          });
          reject(error);
        });
    });
  },
  delete(context, fields) {
    return new Promise((resolve, reject) => {
      apiService
        .post(endpoints.delete, {
          id: fields.id,
        })
        .then((response) => {
          let payload = response;
          context.commit(mutation_calls.delete, fields);
          context.dispatch("system/setMessage", i18n.t("scans.delete.status.success"), { root: true });
          resolve(payload);
        })
        .catch((error) => {
          context.dispatch("system/setMessage", i18n.t("general.error"), {
            root: true,
          });
          reject(error);
        });
    });
  },
  async updateRecommendations(context, fields) {
    return new Promise((resolve, reject) => {
      let apiParams = {
        recommendations: fields.recommendations,
        id: fields.id,
      };
      apiService
        .post(endpoints.updateRecommendations, apiParams)
        .then((response) => {
          let payload = response;
          context.commit(mutation_calls.updateRecommendations, payload);
          resolve(payload);
        })
        .catch((error) => {
          context.dispatch("system/setMessage", i18n.t("general.error"), {
            root: true,
          });
          reject(error);
        });
    });
  },
  async updateLabel(context, fields) {
    return new Promise((resolve, reject) => {
      let apiParams = {
        report_label: fields.report_label,
        id: fields.id,
      };
      apiService
        .post(endpoints.updateLabel, apiParams)
        .then((response) => {
          let payload = response;
          context.commit(mutation_calls.updateLabel, payload);
          resolve(payload);
        })
        .catch((error) => {
          context.dispatch("system/setMessage", i18n.t("general.error"), {
            root: true,
          });
          reject(error);
        });
    });
  },
  async updateReport(context, fields) {
    return new Promise((resolve, reject) => {
      let apiParams = {
        report_pdf: fields.report_pdf,
        id: fields.id,
      };
      apiService
        .post(endpoints.updateReport, apiParams)
        .then((response) => {
          let payload = response;
          context.commit(mutation_calls.updateReport, payload);
          resolve(payload);
        })
        .catch((error) => {
          context.dispatch("system/setMessage", i18n.t("general.error"), {
            root: true,
          });
          reject(error);
        });
    });
  },
  sendReport(context, fields) {
    const payload = { ...fields, attachments: JSON.stringify(fields.attachments) };
    return new Promise((resolve, reject) => {
      apiService
        .post(endpoints.sendReport, payload)
        .then((response) => {
          context.dispatch("system/setMessage", "Email sent successfully!", {
            root: true,
          });
          resolve(response);
        })
        .catch((error) => {
          context.dispatch("system/setMessage", i18n.t("general.error"), { root: true });
          reject(error);
        });
    });
  },
  start(context, fields) {
    return new Promise((resolve, reject) => {
      apiService
        .post(endpoints.start, fields)
        .then((response) => {
          let payload = response;
          resolve(payload);
        })
        .catch((error) => {
          context.dispatch("system/setMessage", i18n.t("general.error"), { root: true });
          reject(error);
        });
    });
  },
  end(context, fields) {
    return new Promise((resolve, reject) => {
      apiService
        .post(endpoints.end, fields)
        .then((response) => {
          let payload = response;
          context.commit(mutation_calls.delete, fields);
          resolve(payload);
        })
        .catch((error) => {
          context.dispatch("system/setMessage", i18n.t("general.error"), { root: true });
          reject(error);
        });
    });
  },
  async lighthouse(context, fields) {
    return new Promise((resolve, reject) => {
      apiService
        .post("/test/lighthouse", {
          url: fields.url,
        })
        .then((response) => {
          let payload = response.data;
          if (!payload.error) {
            context.commit("SET_LIGHTHOUSE", payload);
          }
          resolve(payload);
        })
        .catch((error) => {
          context.dispatch("system/setMessage", i18n.t("general.error"), {
            root: true,
          });
          reject(error);
        });
    });
  },
  async clickjacking(context, fields) {
    return new Promise((resolve, reject) => {
      apiService
        .post("/test/clickjacking", {
          url: fields.url,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async accessibility(context, fields) {
    return new Promise((resolve, reject) => {
      apiService
        .post("/test/accessibility", {
          url: fields.url,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async performance(context, fields) {
    return new Promise((resolve, reject) => {
      apiService
        .post("/test/performance", {
          url: fields.url,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_ALL(state, payload) {
    state.all = payload;
  },
  SET_BYCLIENT(state, payload) {
    state.client = payload;
  },
  SET_UPDATE(state, payload) {
    Vue.set(state.all, payload.index, payload);
  },
  SET_UPDATE_ATTACHMENTS(state, payload) {
    let index = state.all.findIndex((scan) => scan.id == payload.id);
    Vue.set(state.all, index, payload);
  },
  SET_DELETE(state, payload) {
    let index = state.all.findIndex((scan) => scan.id == payload.id);
    state.all.splice(index, 1);
  },
  SET_INSERT(state, payload) {
    state.all.push(payload);
  },
  SET_DOMAIN(state, payload) {
    state.domain = payload;
  },
  SET_GET(state, payload) {
    state.get = payload;
  },
  SET_DATA(state, payload) {
    state.data = payload;
  },
  SET_ID(state, payload) {
    state.data.id = payload;
  },
  SET_PAGES(state, payload) {
    state.pages = payload;
  },
  SET_PAGELINKS(state, payload) {
    state.data.pagelinks = payload;
  },
  SET_CLICKJACKING(state, payload) {
    state.clickjacking = payload;
  },
  SET_SCAN_START(state, payload) {
    state.process = payload;
  },
  SET_SCAN_COMPLETED(state, payload) {
    let index = state.all.findIndex((scan) => scan.id == payload.id);
    Vue.set(state.all, index, payload);
  },
  SET_RESET(state) {
    Object.assign(state, initialState());
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
