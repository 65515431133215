import Vue              from 'vue'
import Vuex             from 'vuex'

import gtmetrix         from './gtmetrix'
import integrations     from './integrations'
import posts            from './posts'
import categories       from './categories'
import logs             from './userLogs'
import managewp         from './managewp'
import messages         from './messages'
import userMeta         from './userMeta'
import scans            from './scans'
import sites            from './sites'
import siteNotes        from './siteNotes'
import slack            from './slack'
import support          from './support'
import system           from './system'
import transactions     from './transactions'
import users            from './users'
import uptime           from './uptime'
import wordpress        from './wordpress'
import emailTemplates   from './emailTemplates'
import auditQuestions   from './auditQuestions'
import auditAnswers     from './auditAnswers'

Vue.use(Vuex)

const debug = process.env.ENV !== 'PROD'

export default new Vuex.Store({
  modules: {
    gtmetrix,
    integrations,
    posts,
    categories,
    logs,
    managewp,
    messages,
		scans,
    sites,
    siteNotes,
		slack,
    support,
    system,
    transactions,
    uptime,
    users,
    userMeta,
		wordpress,
    emailTemplates,
    auditQuestions,
    auditAnswers
  },
  strict: debug,
})
