import Vue from "vue";

import Editor                       from "@tinymce/tinymce-vue";
import Gravatar                     from 'vue-gravatar';

import CreditsAlert                 from './CreditsAlert.vue';
import Header                       from './Header.vue';
import Footer                       from './Footer.vue';
import ISPLogos                     from './ISPLogos.vue';
import NoResults                    from './NoResults.vue';
import ReportSelector               from './ReportSelector.vue';
import SystemMessage                from './SystemMessage.vue';

Vue.component("v-editor",           Editor);
Vue.component('v-gravatar',         Gravatar);

Vue.component('v-credits-alert',    CreditsAlert);
Vue.component('v-header',           Header);
Vue.component('v-foot',             Footer);
Vue.component('v-isp-logos',        ISPLogos);
Vue.component('v-no-results',       NoResults);
Vue.component('v-report-selector',  ReportSelector);
Vue.component('v-system-message',   SystemMessage);

import "./base";
import "./icons";
import "./layout";
import "./modals";
import "./sections";
import "./tests";
import "./utils";
