let st = null;
import utilities from "@/services/utilities.js";

const state = () => ({
  message: null,
  show: false,
  hideHelp: false,
})

const getters = {
  message: state => state.message,
  show: state => state.show,
  hideHelp: state => state.hideHelp,
}

const actions = {
  setMessage(context, payload) {
    context.commit("SET_MESSAGE", payload);
    st = setTimeout(() => {
      context.dispatch('clearMessage'); 
    },5000);  
  },
  setShow(context, payload) {
    clearTimeout(st)
    context.commit("SET_SHOW_MESSAGE", payload);
  },
  clearMessage(context) {
    context.commit("SET_CLEAR_MESSAGE");
  },
  setHelp(context, payload) {
    context.commit("SET_HELP", payload);
  },
  async getHelp() {
    return await utilities.getCookie('hideHelp', false);
  },
}

const mutations = {
  SET_SHOW_MESSAGE(state, payload) {
    state.show_message = payload;
  },  
  SET_MESSAGE(state, payload) {
    state.message = payload;
    state.show_message = true;
  },  
  SET_CLEAR_MESSAGE(state) {
    state.message = '';
    state.show_message = false;
  },  
  SET_HELP(state, payload) {
    state.hideHelp = payload;
    utilities.setCookie('hideHelp', payload, 3000);
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}