<template>
  <div class="attachment">
    <v-modal-scan-attachment-rename v-model="dialogRename" :id="id" />
    <v-layout>
      <v-flex class="pa-0 mb-3" align-self-center>
        <v-overlay :value="loading" absolute color="primary">
          <v-progress-circular indeterminate size="64" color="primary" />
        </v-overlay>

        <v-list outlined class="py-0" style="height: 300px; max-height: 300px; overflow-y: auto;" v-if="fileList.length > 0">
          <v-list-item-content class="pa-0">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th style="width: 30px;"></th>
                    <th>Label</th>
                    <th>File</th>
                    <th style="width: 150px;">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in fileList"
                    :key="index"
                    style="border-bottom: 1px solid #e0e0e0;">
                    <td align="center">
                      <v-avatar
                        color="secondary"
                        size="18"
                      >
                        <span class="white--text text-caption">{{index+1}}</span>
                      </v-avatar>
                    </td>
                    <td>
                      <v-layout>
                        <v-flex v-if="item.file === null">
                          <v-text-field
                            v-model="item.label"
                            outlined 
                            dense  
                            hide-details="auto"
                            class="rounded-0"
                            required
                            placeholder="Enter a label for this attachment"
                            >
                          </v-text-field>
                        </v-flex>
                        <v-flex v-else>
                          <span v-if="item.uploaded === true" class="rounded-0">{{ item.label }}</span>
                          <span v-else class="rounded-0">{{ item.label }}</span>
                        </v-flex>
                      </v-layout>
                    </td>
                    <td>
                      <v-layout>
                        <v-flex v-if="item.file === null">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn small block dark elevation="0" color="primary" v-bind="attrs" v-on="on" @click="onFileUpload(index)" :disabled="item.uploaded === false">
                                <v-icon color="white" small>mdi-attachment</v-icon>
                                File Select
                              </v-btn>
                            </template>
                            <span>Upload</span>
                          </v-tooltip>
                        </v-flex>
                        <v-flex v-else>
                          <a :href="item.file" 
                            target="_blank" 
                            rel="noopener noreferrer"  
                            class="text-caption" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: block;">
                            <span>{{ item.file }}</span>
                            <v-icon x-small>mdi-open-in-new</v-icon>
                          </a>
                        </v-flex>
                      </v-layout>
                    </td>
                    <td align="center">
                      <v-btn-toggle mandatory background-color="secondary" class="ml-2">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn small color="secondary" icon v-bind="attrs" v-on="on" @click="onClickOpenDialogRename(item)" :disabled="item.file === null">
                              <v-icon color="white" small>mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>Rename</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn small color="secondary" icon v-bind="attrs" v-on="on" :href="item.file" target="_blank" :disabled="item.file === null">
                              <v-icon color="white" small>mdi-magnify</v-icon>
                            </v-btn>
                          </template>
                          <span>View</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn small color="secondary" icon v-bind="attrs" v-on="on" @click="onFileRemove(index)">
                              <v-icon color="white" small>mdi-close</v-icon>
                            </v-btn>
                          </template>
                          <span>Delete Document</span>
                        </v-tooltip>
                      </v-btn-toggle>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-list-item-content>
          <v-list-item-action>
            <v-layout class="justify-space-between" style="width: 100%;">
              <v-flex>
                <v-btn dark color="primary" small @click="onAdd">
                  <v-icon size="12" style="margin-right: 5px;">mdi-plus</v-icon>
                  <span>Add Attachment</span>
                </v-btn>
              </v-flex>
              <v-flex class="text-right">
                <v-btn v-if="loading" :loading="loading" dark color="primary" @click="onSave()" small>Upload Files</v-btn>
              </v-flex>
            </v-layout>
          </v-list-item-action>
        </v-list>
        <v-list outlined class="py-0" style="height: 300px; max-height: 300px; overflow-y: auto;" v-else>
          <v-subheader class="list-header">
            <v-btn dark color="primary" small @click="onAdd">
              <v-icon size="12" style="margin-right: 5px;">mdi-plus</v-icon>
              <span>Add</span>
            </v-btn>
          </v-subheader>
          <v-list-item>
            No documents attached yet!
          </v-list-item>
        </v-list>
        <v-layout>
          <v-flex>
            <v-file-input ref="fileUploader" v-model="fileUploader" label="Choose the document" outlined accept="image/*,.pdf" @change="onFileSelected" style="display: none;" />
          </v-flex>
        </v-layout>
      </v-flex>
    
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "Attachment.vue",
  data() {
    return {
      form: {
        valid: false,
        fields: {
          id: 0,
          files: [],
        },
      },
      dialogRename: false,
      saving: false,
      loading: false,
      fileUploader: [],
      fileIndex: 0
    };
  },
  props: {
    item: {
      type: Object,
    },
    id:{
      type: [Number, String]
    },
  },
  mounted() {
    this.init();
  },
  computed: {
    fileList() {
      return this.form.fields.files.filter((item) => item.removed === false);
    },
    allowSave() {
      return this.form.fields.files.every((item) => item.file !== null);
    }
  },
  methods: {
    init() {
      this.loading = false;
      this.form.fields.id = this.item.id;
      this.form.fields.files = this.item.attachments ? this.item.attachments.map((item) => ({ label: item.file_label, file: item.file_url, removed: false, uploaded: false, name: "" })) : [];
    },
    isLabelUnique(label, currentIndex) {
      return this.form.fields.files.filter((item, idx) => item.label === label && idx !== currentIndex).length === 0;
    },
    onAdd() {
      // const Cnt = this.form.fields.files.length;
      // this.form.fields.files.push({ file: null, label: `Attachment ${Cnt}`, url: "", removed: false, uploaded: true, name: "" });
      this.form.fields.files.push({ file: null, label: '', url: "", removed: false, uploaded: true, name: "" });
    },
    onFileUpload(index) {
      this.fileIndex = index;
      const fileInputElement = this.$refs.fileUploader.$el.querySelector('input[type="file"]');
      if (fileInputElement) {
        fileInputElement.click();
      } else {
        console.error("File input element not found.");
      }
    },
    onFileSelected(file) {
      if (file && this.fileIndex >= 0) {
        this.form.fields.files[this.fileIndex].file = file;
        this.form.fields.files[this.fileIndex].label = `Attachment - ${this.fileIndex}`;
        this.form.fields.files[this.fileIndex].name = `${file.name}`;
        this.fileUploader = null;
      }
    },
    onFileRemove(index) {
      const item = this.form.fields.files[index];
      if (item.uploaded === false) {
        this.form.fields.files[index].removed = true;
        return;
      }
      this.form.fields.files.splice(index, 1);
      let Cnt = 0;
      if (this.form.fields.files) {
        this.form.fields.files.map((f) => {
          if (f.file === null) {
            Cnt += 1;
            f.label = `Attachment ${Cnt}`
          }
          return f;
        });
      }
    },
    onFileView(item) {
      console.log(item);
      if (item.file) {
        const fileUrl = URL.createObjectURL(item.file);
        window.open(fileUrl, '_blank');
      }
    },
    onSave() {
      this.loading = true;
      this.$emit("save", this.form.fields, this.item);
    },
    onClickOpenDialogRename(item) {
      this.selectedAttachmentId = item.id;
      this.dialogRename = true;
    },
  },
  watch: {
    value(to) {
      if (to) {
        this.init();
      }
    },
  },
};
</script>

<style scoped>
.v-list-item__icon {
  align-self: center;
}
.list-header {
  border-bottom: 1px solid #ddd;
  padding: 0 6px !important;
}
.chip-label {
  margin-left: 10px;
  display: flex;
  align-items: center;
  width: 250px;
}
</style>