import Vue            from 'vue'
import apiService     from '@/services/api.js';
import i18n           from '@/lang';

const model = '/messages';
const endpoints = {
  all:              model+'/all',
  insert:           model+'/insert',
  update:           model+'/update',
  delete:           model+'/delete',
}
const mutation_calls = {
  all:              'SET_ALL',
  insert:           'SET_INSERT',
  update:           'SET_UPDATE',
  delete:           'SET_DELETE',
  reset:            'SET_RESET',
}

const initialState = () => ({
  all: null,
});

const state = initialState();

const getters = {
  all: state => state.all,
}

const actions = {
  all(context) {
    return new Promise((resolve, reject) => {
      if(context.rootGetters['messages/all'] != null){
        return;
      }
      apiService.post(endpoints.all,{})
      .then(response => {
        let payload = response.data;
        context.commit(mutation_calls.all, payload);
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("messages.all.status.error"), { root: true })
        reject(error);
      });
    });
  },
  insert(context, fields) {
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.insert, {
        'content': fields.content,
        'link': fields.link
      })
      .then(response => {
        let payload = response.data[0];
        context.commit(mutation_calls.insert, payload);
        context.dispatch('system/setMessage', i18n.t("messages.insert.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("messages.insert.status.error"), { root: true })
        reject(error);
      });
    });
  },
  update(context, fields) {
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.update, {
        'content': fields.content,
        'link': fields.link,
        'id': fields.id
      })
      .then(response => {
        let payload = response;
        context.commit(mutation_calls.update, fields);
        context.dispatch('system/setMessage', i18n.t("messages.update.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("messages.update.status.error"), { root: true })
        reject(error);
      });
    });
  },
  delete(context, fields) {
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.delete, { 
        'id': fields.id,
      })
      .then(response => {
        let payload = response;
        context.commit(mutation_calls.delete, fields.index);
        context.dispatch('system/setMessage', i18n.t("messages.delete.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("messages.delete.status.error"), { root: true })
        reject(error);
      });
    });
  },
}

const mutations = {
  SET_ALL(state, payload) {
    state.all = payload.sort((t1,t2) => t1.id > t2.id ? -1 : 1);
  },
  SET_INSERT(state, payload) {
    state.all.push(payload);
  },
  SET_UPDATE(state, payload) {
    Vue.set(state.all, payload.index, payload)
  },
  SET_DELETE(state, payload) {
    state.all.splice(payload, 1);
  },
  SET_RESET(state) {
    Object.assign(state, initialState());
  }
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}