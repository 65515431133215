<template>
  <div class="sidebar-zen">
    <v-layout row wrap align-center text-center fill-height class="gray-gradient border-0 pa-0 ma-0 rounded-xl">
      <v-flex>
        <h2 class="nabla">{{this.lineOne}}</h2>
        <h2 class="nabla">{{this.lineTwo}}</h2>
        <h2 class="nabla">{{this.lineThree}}</h2>
        <lottie-animation
          ref="anim"
          :loop="true"
          :animationData="require('@/assets/zen.json')"
          style="width:260px;height:220px;margin: auto;"
        />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import LottieAnimation  from 'lottie-web-vue'
  export default {
    name: 'Zen.vue',
    data() {
      return {

      }
    },
    props:{
      lineOne:{
        type: String,
        default: ''
      },
      lineTwo:{
        type: String,
        default: ''
      },
      lineThree:{
        type: String,
        default: ''
      },
    },

    components: {
      LottieAnimation
    },
    computed:{
    }
  }
</script>

<style>
  /* 
  .nabla:nth-child(1){
    font-size: 56px;
  }
  .nabla:nth-child(2){
    font-size: 42px;
  }
  .nabla:nth-child(3){
    margin-top: -5px;
    font-size: 124px;
  }
  */
  .nabla{
    font-size: 2.8em;
    line-height: 1.4em;
  }
  .sidebar-zen{
    height: 520px;
  }
</style>