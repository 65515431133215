import Vue            from 'vue'
import apiService     from '@/services/api.js';
import i18n           from '@/lang';

const model = '/posts';
const endpoints = {
  all:              model+'/all',
  get:              model+'/get',
  insert:           model+'/insert',
  update:           model+'/update',
  delete:           model+'/delete',
  byCategory:       model+'/category',
  byParent:         model+'/parent',
}
const mutation_calls = {
  all:              'SET_ALL',
  get:              'SET_GET',
  insert:           'SET_INSERT',
  update:           'SET_UPDATE',
  delete:           'SET_DELETE',
  byCategory:       'SET_BY_CATEGORY',
  byParent:         'SET_BY_PARENT',
}

const state = () => ({
  all: null,
  get: null,
  byCategory: null,
  byParent: null
})

const getters = {
  all: state => state.all,
  get: state => state.get,
  byCategory: state => state.byCategory,
  byParent: state => state.byParent,
}

const actions = {
  all(context) {
    return new Promise((resolve, reject) => {
      if(context.rootGetters['posts/all'] != null){
        return;
      }
      apiService.post(endpoints.all,{})
      .then(response => {
        let payload = response.data;
        context.commit(mutation_calls.all, payload);
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("posts.all.status.error"), { root: true })
        reject(error);
      });
    });
  },
  async get(context, fields) {
    return new Promise((resolve, reject) => {
      if(fields.id == 0){
        return resolve();
      }
      let apiParams = { 
        'id': fields.id
      };
      apiService.post(endpoints.get, apiParams)
      .then(response => {
        let payload = response.data;
        context.commit(mutation_calls.get, payload);
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("posts.all.status.error"), { root: true })
        reject(error);
      });
    });
  },
  insert(context, fields) {
    return new Promise((resolve, reject) => {
      let user = context.rootGetters['users/user'];

      apiService.post(endpoints.insert, {
        'title': fields.title,
        'message': fields.message,
        'user_id': user.id,
        'category_id': fields.category_id,
        'parent_id': fields.parent_id        
      })
      .then(response => {
        let payload = response.data[0];
        context.commit(mutation_calls.insert, payload);
        context.dispatch('system/setMessage', i18n.t("posts.insert.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("posts.insert.status.error"), { root: true })
        reject(error);
      });
    });
  },
  update(context, fields) {
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.update, {
        'title': fields.title,
        'message': fields.message,
        'category_id': fields.category_id,
        'id': fields.id
      })
      .then(response => {
        let payload = response;
        context.commit(mutation_calls.update, fields);
        context.dispatch('system/setMessage', i18n.t("posts.update.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("posts.update.status.error"), { root: true })
        reject(error);
      });
    });
  },
  delete(context, fields) {
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.delete, { 
        'id': fields.id,
      })
      .then(response => {
        let payload = response;
        context.commit(mutation_calls.delete, fields.index);
        context.dispatch('system/setMessage', i18n.t("posts.delete.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("posts.delete.status.error"), { root: true })
        reject(error);
      });
    });
  },
  category(context, fields) {
    return new Promise((resolve, reject) => {
      // console.log(context.rootGetters['posts/byCategory']);
      // if(context.rootGetters['posts/byCategory'] != null){
      //   return;
      // }
      apiService.post(endpoints.byCategory, {
        'category_id': fields.category_id
      })
      .then(response => {
        let payload = response.data;
        context.commit(mutation_calls.byCategory, payload);
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("posts.all.status.error"), { root: true })
        reject(error);
      });
    });
  },
  parent(context, fields) {
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.byParent, {
        'parent_id': fields.parent_id
      })
      .then(response => {
        let payload = response.data;
        context.commit(mutation_calls.byParent, payload);
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("posts.all.status.error"), { root: true })
        reject(error);
      });
    });
  },
}

const mutations = {
  SET_ALL(state, payload) {
    state.all = payload;
  },
  SET_GET(state, payload) {
    state.get = payload;
  },  
  SET_INSERT(state, payload) {
    state.all.push(payload);
    // state.byCategory = payload;
    // state.byParent = payload;
  },
  SET_UPDATE(state, payload) {
    Vue.set(state.all, payload.index, payload)
  },
  SET_DELETE(state, payload) {
    state.all.splice(payload, 1);
  },
  SET_BY_CATEGORY(state, payload) {
    state.byCategory = payload;
  },
  SET_BY_PARENT(state, payload) {
    state.byParent = payload;
  },
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}