<template>
  <v-dialog v-model="value" max-width="650px" persistent>
    <v-form ref="form" v-model="form.valid">
      <v-card>
        <v-card-title>
          <span class="text-h5">Update User</span>
          <v-spacer></v-spacer>
          <v-btn small dark fab right text @click="updateValue(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center">
                An email will be sent to the user to set their password. 
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="form.fields.name"
                  type="text"
                  outlined
                  required
                  placeholder="Name"
                  @keydown.enter.prevent
                  label="Name"
                  :rules="[$utilities.rules.required]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="form.fields.email"
                  type="text"
                  outlined
                  required
                  placeholder="Email"
                  @keydown.enter.prevent
                  label="Email"
                  :rules="[$utilities.rules.required, $utilities.rules.isEmail]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-5">
          <v-spacer></v-spacer>
          <v-btn dark color="primary" @click="save">Save</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: 'UsersUpdate.vue',
  data() {
    return {
      form:{
        valid: false,
        fields:{
          name: '',
          email: '',
        }
      },
    }
  },
  emits: ['update:modelValue'],
  props:{
    value:{
      type: Boolean,
      default: false
    },
    id:{
      type: Number
    },
  },
  computed:{
    users(){
      return this.$store.getters['users/all'];
    },
  },
  created(){
    this.get();
  },
  methods:{
    updateValue (value) {
      this.$emit('input', value);
    },
    async get(){
      if(this.users){
        let user = this.users.find(user => user.id == this.id);
        this.form.fields = {...user};
      }
    },
    async save(){
      if(this.$refs.form.validate()){
        await this.$store.dispatch("users/update", {...this.form.fields, id: this.id});
        this.updateValue(false);
      }else{
        this.systemMessage('Please validate the form!');
      }
    }
  },
  watch:{
    id(){
      this.get();
    }
  }
}
</script>

<style>

</style>