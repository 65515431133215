<template>
  <v-dialog v-model="value" max-width="650px" persistent>
    <v-card>
      <v-card-title>
        <span class="text-h5">Delete Category?</span>
        <v-spacer></v-spacer>
        <v-btn small dark fab right text @click="updateValue(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="text-center py-5" v-if="this.categories">
        <b>Are you sure you want to delete <b>{{getCategoryName}}</b>?</b>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="py-5">
        <v-spacer></v-spacer>
        <v-btn dark color="primary" @click="save">Yes</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'PostCategoryDelete.vue',
  data() {
    return {
    }
  },
  emits: ['update:modelValue'],
  props:{
    value:{
      type: Boolean,
      default: false
    },
    id:{
      type: [Number, String]
    },
  },
  computed:{
    categories(){
      return this.$store.getters['categories/all'];
    },
    getCategoryName(){
      let category = this.categories.find(category => category.id == this.id);
      return (category) ? category.name : "";
    }
  },
  methods:{
    updateValue (value) {
      this.$emit('input', value);
    },
    async save(){
      let self = this;
      let index = this.categories.findIndex(category => category.id == this.id);
      await this.$store.dispatch("categories/delete", {
        id: this.id,
        index: index
      })
      .then(() =>{
        self.updateValue(false);
      });
    }
  }
}
</script>

<style>

</style>