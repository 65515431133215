<template>
  <v-form
    ref="form"
    v-model="form.valid"
    lazy-validation>
    <v-card elevation="0">
      <v-card-title>Maintenance Cycle</v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col>
            <v-select
              label="Cycle"
              outlined
              v-model="form.fields.cycle"
              :items="$strings.cycleItems()"
              item-value="value"
              item-text="text"
              :rules="[$utilities.rules.required]"
              hint="This is the monthly cycle when work will begin. This will be reflected on the calendar widget."
              :persistent-hint="true"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-row>
          <v-col class="text-center">
            <v-btn x-large dark color="primary" @click="save">
              Save
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>  
  </v-form>
</template>

<script>
export default {
  name: 'MaintenanceCycle.vue',
  data() {
    return {
      form:{
        valid: false,
        fields: {
          cycle: 0,
        },
      },
    }
  },
  computed:{
    meta(){
      return this.$store.getters['userMeta/get'];
    }
  },
  async created(){
    // this.form.fields.cycle = parseInt(this.meta.cycle);
  },
  methods:{
    save(){
      let self = this;
      if(this.$refs.form.validate()){
        this.$store.dispatch("userMeta/updateCycle", this.form.fields)
        .then(() => {
          self.systemMessage('Settings saved!');
        });
      }
    },
  },
  watch:{
    meta (to){
      this.form.fields.cycle = parseInt(to.cycle);
    }
  }
}
</script>

<style>

</style>