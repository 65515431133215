<template>
  <v-dialog v-model="value" max-width="900px" width="80%" persistent>
    <v-form ref="form" v-model="form.valid">
      <v-card>
        <v-card-title>
          <span class="text-h5">Update Question</span>
          <v-spacer></v-spacer>
          <v-btn small dark fab right text @click="updateValue(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col>
                <v-text-field
                  v-model="form.fields.question"
                  type="text"
                  outlined
                  required
                  placeholder="Question"
                  @keydown.enter.prevent
                  label="Question"
                  :rules="[$utilities.rules.required]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <p class="mb-2">If active, this question will be used in the audit.</p>
                <v-switch
                  v-model="form.fields.active"
                  label="Active"
                  inset
                  :true-value="1"
                  :false-value="0"
                  hide-details
                  color="green2"
                ></v-switch>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="form.fields.sort"
                  type="number"
                  outlined
                  required
                  placeholder="Sort"
                  @keydown.enter.prevent
                  label="Sort"
                  min="1"
                  max="100"
                  :rules="[$utilities.rules.required]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-5">
          <v-spacer></v-spacer>
          <v-btn dark color="primary" @click="save">Save</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: 'AuditQuestionUpdate.vue',
  data() {
    return {
      form:{
        valid: false,
        fields:{
          question: '',
          sort: 1,
          active: 1,
        }
      },
    }
  },
  emits: ['update:modelValue'],
  props:{
    value:{
      type: Boolean,
      default: false
    },
    id:{
      type: [Number, String]
    },
  },
  created(){
    this.get();
  },
  methods:{
    updateValue (value) {
      this.$emit('input', value);
    },
    async get(){
      this.question = await this.$store.dispatch('auditQuestions/get', {id: this.id});
      if(this.question){
        this.form.fields = {...this.question};
      }
    },
    async save(){
      if(this.$refs.form.validate()){
        await this.$store.dispatch("auditQuestions/update", {...this.form.fields, id: this.id, index: this.index});
        this.updateValue(false);
      }else{
        this.systemMessage('Please validate the form!');
      }
    }
  },
  watch:{
    id(){
      this.get();
    }
  }
}
</script>

<style>

</style>